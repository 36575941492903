<template>
  <b-overlay
    class="pb-4 wallet-family-table"
  >
    <!-- Create New Percent Based Wallet -->
    <create-split-wallet
      v-if="createWallet"
      :user-type="userType"
      :is-default-wallet="false"
      :used-banks="usedBanks"
      :family-type="familyTypeToCreate"
      @wallet-config-success="() => { createWallet = false; getWallet() }"
      @back="() => createWallet = false"
    />

    <!-- Wallets List -->
    <div v-else>
      <b-row class="justify-content-between">
        <b-col :cols="canEditCompany ? '9' : '12'">
          <new-account-dropdown
            id="tour-add-wallet"
            class="d-md-none mb-1"
            @add-percent-based="() => { familyTypeToCreate = 'PERCENTBASED'; createWallet = true }"
            @add-open-wallet="() => { familyTypeToCreate = 'OPENWALLET'; createWallet = true }"
          />
        </b-col>

        <b-col cols="3">
          <b-button
            v-if="canEditCompany"
            id="tour-scheduled-transfers"
            class="position-relative d-md-none py-1"
            variant="primary"
            @click="viewScheduledTransfers"
          >
            <i class="fa fa-clock-rotate-left position-absolute clock-icon" />
            <i class="fa fa-calendar-days position-relative calendar-icon" />
          </b-button>
        </b-col>
      </b-row>

      <b-row
        class="align-items-center w-100"
        :class="IS_MOBILE() ? 'justify-content-between' : 'justify-content-end'"
      >
        <!-- total balance -->
        <b-col
          cols="10"
          md="2"
          class="px-md-0"
          :style="!IS_MOBILE() ? 'marginRight: -24px;' : ''"
        >
          <h6
            class="cursor-pointer"
            @click="showBalance = !showBalance"
          >
            <span class="mr-1">{{ $t('Total Balance') }}:</span>
            <span v-if="showBalance">{{ formatAmount(totalBalance) }}</span>
            <span v-else>XXX.XX</span>
            <feather-icon
              class="ml-1 cursor-pointer"
              :icon="showBalance ? 'EyeOffIcon' : 'EyeIcon'"
            />
          </h6>
        </b-col>

        <b-col
          cols="2"
          md="1"
          class="d-flex justify-content-end px-0"
          style="margin-right: -10px;"
        >
          <b-button
            id="tour-family-statement"
            v-b-tooltip.hover="IS_MOBILE() ? '' : $t('View Statement')"
            variant="wz-teal"
            class="ml-1 mb-1"
            @click="$router.push({ name: 'wallet-statement', params: { userType, walletFamily: wallet.uuid, company, overall: true } })"
          >
            <i class="fas fa-file-lines" />
          </b-button>
        </b-col>
      </b-row>

      <b-overlay
        class="wallet-table"
        :show="isLoading"
      >
        <b-table-simple
          :responsive="IS_MOBILE()"
          borderless
        >
          <!-- Table Headers -->
          <b-tr class="table-header d-flex align-items-center">
            <b-th
              v-for="field in fields"
              :key="field.key"
              :class="field.classes"
              :style="IS_MOBILE() ? field.mobileStyles : ''"
            >
              <h6
                v-if="field.key !== 'actions'"
                class="mb-0"
              >
                {{ field.label }}
              </h6>
              <new-account-dropdown
                v-else
                id="tour-add-wallet"
                class="d-none d-md-block"
                @add-percent-based="() => { familyTypeToCreate = 'PERCENTBASED'; createWallet = true }"
                @add-open-wallet="() => { familyTypeToCreate = 'OPENWALLET'; createWallet = true }"
              />
            </b-th>
          </b-tr>

          <!-- Table Body -->

          <!-- Mobile View without Draggable -->
          <div v-if="walletFamily.length && IS_MOBILE()">
            <wallet-family-table
              :used-banks="usedBanks"
              :wallet-family="walletFamily"
              :user-type="userType"
              :show-balance="showBalance"
              :details-showing-family="detailsShowingFamily"
              :all-wallet-family="allWalletFamily"
              :is-wallet-shared="isWalletShared"
              :get-shared-direction="GET_SHARED_DIRECTION"
              :hovered-family="hoveredFamily"
              @update-detail-showing-family="uuid => detailsShowingFamily = detailsShowingFamily === uuid ? null : uuid"
              @update-hovered-family="uuid => hoveredFamily = uuid"
              @fetch-wallet="selectedWallet => getWallet(selectedWallet)"
              @wallet-updated="onWalletUpdated"
              @balance-toggle="showBalance = !showBalance"
              @show-modal="(account, id) => showModal(account, id)"
            />
          </div>

          <!-- Desktop View with Draggable -->
          <draggable
            v-else-if="walletFamily.length"
            v-model="walletFamily"
            @end="orderWalletFamily"
          >
            <wallet-family-table
              :used-banks="usedBanks"
              :wallet-family="walletFamily"
              :user-type="userType"
              :show-balance="showBalance"
              :details-showing-family="detailsShowingFamily"
              :all-wallet-family="allWalletFamily"
              :is-wallet-shared="isWalletShared"
              :get-shared-direction="GET_SHARED_DIRECTION"
              :hovered-family="hoveredFamily"
              @update-detail-showing-family="uuid => detailsShowingFamily = detailsShowingFamily === uuid ? null : uuid"
              @update-hovered-family="uuid => hoveredFamily = uuid"
              @fetch-wallet="selectedWallet => getWallet(selectedWallet)"
              @wallet-updated="onWalletUpdated"
              @balance-toggle="showBalance = !showBalance"
              @show-modal="(account, id) => showModal(account, id)"
            />
          </draggable>

          <div v-else>
            <p class="striped text-center py-1">
              {{ $t('messages.no-accounts') }}
            </p>
          </div>
        </b-table-simple>
      </b-overlay>
    </div>

    <!-- Edit Wallet Family Alias -->
    <b-modal
      v-if="selectedWalletFam"
      id="edit-fam-details-modal"
      :title="$t('Rename Account')"
      hide-footer
      no-close-on-backdrop
    >
      <edit-wallet-alias
        :wallet-fam="selectedWalletFam"
        :user-type="userType"
        wallet-type="family"
        :no-title="true"
        @cancel="() => { $bvModal.hide('edit-fam-details-modal')}"
        @wallet-updated="alias => onWalletFamilyUpdated(alias)"
      />
    </b-modal>

    <!-- Add New Open Wallet -->
    <add-new-open-wallet
      v-if="selectedWalletFam"
      :user-type="userType"
      :used-banks="usedBanks"
      :wallet="selectedWalletFam"
      @wallet-added="wallet => { getWallet(wallet) }"
    />
  </b-overlay>
</template>

<script>
import {
  BOverlay, BButton, VBTooltip, BTableSimple, BTr, BTh,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import EventBus from '@/event-bus'
import { getUserData } from '@/auth/utils'
import useWallet from '@/utils/wallet'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import CreateSplitWallet from './WalletActions/CreateSplitWallet.vue'
import EditWalletAlias from './WalletActions/EditWalletAlias.vue'
import AddNewOpenWallet from './WalletActions/AddNewOpenWallet.vue'
import WalletFamilyTable from './WalletFamilyTable.vue'
import NewAccountDropdown from './NewAccountDropdown.vue'

const { isWalletShared, GET_SHARED_DIRECTION } = useWallet()

export default {
  components: {
    BOverlay,
    BButton,
    BTableSimple,
    BTr,
    BTh,
    draggable,
    CreateSplitWallet,
    EditWalletAlias,
    AddNewOpenWallet,
    WalletFamilyTable,
    NewAccountDropdown,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    userType: {
      type: String,
      default: () => 'users',
    },
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isWalletShared,
      GET_SHARED_DIRECTION,
      self: getUserData(),
      walletFamily: [],
      fields: [
        {
          key: 'alias', label: this.$t('Alias'), classes: 'col-5 py-1 pl-4', mobileStyles: 'minWidth: 180px',
        },
        {
          key: 'type', label: this.$t('Type'), classes: 'col-4', mobileStyles: 'minWidth: 180px',
        },
        {
          key: 'totalBalance', label: this.$t('Total Balance'), classes: 'col-2', mobileStyles: 'minWidth: 180px',
        },
        { key: 'actions', classes: 'col-1 py-0', mobileStyles: 'minWidth: 180px' },
      ],
      familyTypes: [
        { text: 'Percentage Based Accounts', value: 'PERCENTBASED' },
        { text: 'Open Accounts', value: 'OPENWALLET' },
      ],
      wallet: {},
      accounts: [],
      usedBanks: [],
      isLoading: false,
      currentFam: null,
      selectedWalletFam: null,
      showBalance: false,
      totalBalance: 0,
      payableBalance: 0,
      createWallet: false,
      familyTypeToCreate: '',
      detailsShowingFamily: null,
      hoveredFamily: null,
      showingDropdown: false,
    }
  },
  computed: {
    ...mapGetters('permissions', ['canEditCompany']),
    allWalletFamily() {
      return this.walletFamily.map(({
        uuid, description, shares, familyType,
      }) => ({
        uuid,
        description,
        shares,
        familyType,
      }))
    },
  },
  mounted() {
    this.getWallet()
    EventBus.$on('wallet-share-approved', () => { this.getWallet() })
    EventBus.$on('fetch-wallets', () => { this.getWallet() })
  },
  methods: {
    orderWalletFamily({ oldIndex, newIndex }) {
      if (oldIndex !== newIndex) {
        let api = 'updateUserWalletOrders'
        const orderedWalletUids = this.walletFamily.map(family => family.uuid)

        const params = {
          orderedWalletUids,
        }

        if (this.userType === 'company') {
          api = 'updateCompanyWalletOrders'
          params.companyUid = this.company.uuid
        }

        useApollo[this.userType][api](params).then(response => {
          this.showSuccessMessage({
            data: {
              message: response.data[api].message,
            },
          })
        }).catch(error => {
          this.showErrorMessage(error)
        })
      }
    },
    getWallet(selectedWallet = null) {
      let api = 'getUserWallet'
      const params = {}
      let res = 'me'
      if (this.userType === 'company') {
        api = 'getCompanyWallet'
        params.company = this.company.uuid
        res = 'company'
      }

      this.isLoading = true
      useApollo[this.userType][api](params).then(response => {
        const data = response.data[res]
        this.totalBalance = data?.walletBalance
        this.payableBalance = data?.realBalance
        this.walletFamily = data?.wallets.data

        if (this.walletFamily.length) {
          this.detailsShowingFamily = selectedWallet ? selectedWallet.uuid : this.walletFamily[0].uuid
        }

        this.usedBanks = this.walletFamily.flatMap(family => family.shares).filter(share => share.wallet.__typename === 'BankWallet').map(bankWallet => ({
          id: bankWallet.wallet.information.id,
          alias: bankWallet.wallet.information.alias,
        }))
      }).finally(() => { this.isLoading = false })
    },
    onWalletFamilyUpdated(alias) {
      this.walletFamily.find(fam => fam.uuid === this.selectedWalletFam.uuid).description = alias
      this.$bvModal.hide('edit-fam-details-modal')
      this.selectedWalletFam = null
    },
    onWalletUpdated(family, wallet, alias) {
      this.walletFamily.find(fam => fam.uuid === family).shares.find(share => share.uuid === wallet).description = alias
    },
    showModal(wallet, modal) {
      this.selectedWalletFam = wallet
      this.$nextTick(() => {
        this.$bvModal.show(modal)
      })
    },
    viewScheduledTransfers() {
      this.$router.push({ name: 'scheduled-transfers', params: { userType: this.tabIndex ? 'company' : 'users' } })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/wallet-table.scss";
@import "@core/scss/base/pages/wallet-page.scss";
</style>
