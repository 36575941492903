<template>
  <b-modal
    :id="`select-banking-info-${selectedWallet ? `${selectedWallet.uuid}-` : ''}${index}-${userType}`"
    :title="creating ? $t('Add New Payment Method') : $t('Choose a Payment Method')"
    size="lg"
    :ok-title="$t('Confirm')"
    :cancel-title="$t('Cancel')"
    no-close-on-backdrop
    ok-only
    :ok-disabled="!selected"
    :hide-footer="creating"
    @shown="setloadingToFalse"
    @ok="handleSubmit"
  >
    <!-- Skeleton Loader -->
    <b-skeleton-wrapper :loading="isLoading || isFetching">
      <template #loading>
        <b-row>
          <b-col
            v-for="i in 2"
            :key="i"
            md="6"
          >
            <b-card>
              <b-skeleton
                width="80%"
                height="24px"
              />
              <b-skeleton
                width="60%"
                height="18px"
              />
              <b-skeleton
                width="40%"
                height="18px"
              />
            </b-card>
          </b-col>
        </b-row>
      </template>

      <!-- Content -->
      <b-row v-if="!creating">
        <b-col
          v-for="{information} in bankingInfo"
          :key="information.id"
          md="6"
        >
          <b-card
            class="my-1 cursor-pointer"
            :border-variant="information.id === selected ? 'info' : 'secondary'"
            :value="information.id"
            @click="() => { selected = information.id }"
          >
            <div class="d-flex justify-content-between">
              <h4>{{ information.alias }}</h4>
              <feather-icon
                v-if="information.id === selected"
                icon="CheckIcon"
                size="28"
                color="#28c76f"
              />
            </div>
            <p class="mb-0">
              {{ information.bankName }}
            </p>
            <p>{{ information.bankAccount }}</p>

            <b-card-footer class="text-right">
              {{ getBillingType(information.__typename) }}
            </b-card-footer>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card
            class="my-1 cursor-pointer"
            no-body
          >
            <div
              v-if="!creating"
              class="position-relative"
            >
              <div
                class="new-wallet-wrap text-center cursor-pointer centralize"
                @click="() => { creating = true }"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="42"
                />
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <validation-observer
        v-else
        ref="bankInfoForm"
      >
        <!-- Payment Type Selection -->
        <b-form-group :label="$t('Select Payment Type')">
          <b-form-select
            v-model="paymentType"
            :options="paymentTypes"
            class="mb-1"
          />
        </b-form-group>

        <b-form class="mb-2">
          <swift-form
            v-if="paymentType=='swift'"
            :country-option="countries"
            :is-processing="isLoading"
            :form-data="form"
            @validationForm="validationForm"
            @cancel="() => { creating = false }"
          />
          <ach-form
            v-if="paymentType=='ach'"
            :country-option="countries"
            :is-processing="isLoading"
            :form-data="form"
            @validationForm="validationForm"
            @cancel="() => { creating = false }"
          />
          <fx-form
            v-if="paymentType=='fx'"
            :country-option="countries"
            :is-processing="isLoading"
            :form-data="form"
            @validationForm="validationForm"
            @cancel="() => { creating = false }"
          />
        </b-form>
      </validation-observer>
    </b-skeleton-wrapper>

    <!-- Confirm Modal -->
    <confirm-modal
      v-if="attachWallet && selectedInfo"
      modal="attach-bank-confirm-modal"
      :title="`Attach this account to ${selectedInfo.information.alias}`"
      :alert="selectedWallet.sharedTo ? 'It looks like you have shared this account with others. Linking this account with a bank account will revoke the sharing of the account.' : ''"
      alert-variant="danger"
      :description="`Are you sure you want to attach ${selectedWallet.description} to ${selectedInfo.information.alias}?`"
      @confirm="() => { $emit('payment-select', selected); $bvModal.hide(`select-banking-info-${index}`) }"
    />
  </b-modal>
</template>

<script>
import {
  BModal, BCard, BCardFooter, BRow, BCol, BForm, BFormGroup, BFormSelect, BSkeletonWrapper, BSkeleton,
} from 'bootstrap-vue'
import { ValidationObserver, extend } from 'vee-validate'
import SwiftForm from '@/views/common/UserSettings/components/billing.vue/SwiftForm.vue'
import AchForm from '@/views/common/UserSettings/components/billing.vue/AchForm.vue'
import FxForm from '@/views/common/UserSettings/components/billing.vue/FxForm.vue'
import useApollo from '@/plugins/graphql/useApollo'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import ConfirmModal from './ConfirmModal.vue'

extend('swiftCode', value => {
  if (value.length > 11) {
    return 'The Bank Swift Code must be less than 11 characters'
  } if (value.split(' ').length > 1) {
    return 'The Bank Swift Code cannot contain space'
  }
  return true
})

export default {
  components: {
    BSkeletonWrapper,
    BSkeleton,
    BModal,
    BCard,
    BCardFooter,
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
    BForm,
    ValidationObserver,
    SwiftForm,
    AchForm,
    FxForm,
    ConfirmModal,
  },
  props: {
    bankingInfo: {
      type: Array,
      default: () => null,
    },
    index: {
      type: [Number, String],
      default: () => null,
    },
    userType: {
      type: String,
      default: () => 'users',
    },
    selectedWallet: {
      type: Object,
      default: () => {},
    },
    attachWallet: {
      type: Boolean,
      default: () => false,
    },
    isFetching: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      required,
      selected: null,
      selectedInfo: null,
      creating: false,
      isLoading: false,
      paymentTypes: [
        {
          value: 'swift',
          text: 'Swift',
        },
        {
          value: 'ach',
          text: 'ACH',
        },
        {
          value: 'fx',
          text: 'Fx',
        },
      ],
      steps: ['payment-type-selection', 'details'],
      currentStep: 0,
      form: {},
      paymentType: 'swift',
      countries: null,
    }
  },
  watch: {
    bankingInfo(val) {
      if (val && val.length) {
        this.selected = val[0].information.id
        this.selectedInfo = this.bankingInfo.find(info => info.information.id === this.selected)
      }
    },
  },
  mounted() {
    if (this.bankingInfo && this.bankingInfo.length) {
      this.selected = this.bankingInfo[0].information.id
    }
    if (!this.countries) this.getCountry()
  },
  methods: {
    handleSubmit(bvModal) {
      if (this.attachWallet) {
        bvModal.preventDefault()
        this.selectedInfo = this.bankingInfo.find(info => info.information.id === this.selected)

        this.$nextTick(() => {
          this.$bvModal.show('attach-bank-confirm-modal')
        })
      } else this.$emit('payment-select', this.selected)
    },
    getBillingType(type) {
      switch (type) {
        case 'AchBillingInformation':
          return 'ACH'
        case 'FxBillingInformation':
          return 'FX'
        default:
          return 'SWIFT'
      }
    },
    validationForm(param) {
      this.$refs.bankInfoForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          const form = param

          if (form.__typename === 'AchBillingInformation') this.paymentType = 'ach'
          else if (form.__typename === 'FxBillingInformation') this.paymentType = 'fx'
          delete form.__typename
          delete form.country.__typename

          const params = { input: form }

          if (this.userType === 'company') {
            params.companyUid = this.$store.state.project.selectedCompany
          } else {
            params.userUid = getUserData().uuid
          }

          useApollo[this.userType].postBillingSetting(params, this.paymentType).then(() => {
            this.showSuccess('Billing updated successfully!')
            this.$emit('refetch')
            this.creating = false
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => { this.isLoading = false })
        }
      })
    },
    getCountry() {
      useApollo.company.getCountries().then(response => {
        this.countries = response.data.countries.data
      })
    },
    setloadingToFalse() {
      setTimeout(() => { this.isLoading = false }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/wallet-page.scss";
</style>
