<template>
  <div>
    <div
      v-for="family, index in walletFamily"
      :key="family.uuid"
      class="cursor-move"
    >
      <b-tr
        class="d-flex align-items-center cursor-pointer table-row"
        :class="{striped: index%2}"
        @mouseenter="IS_MOBILE() ? '' : $emit('update-hovered-family', family.uuid)"
        @mouseleave="IS_MOBILE() ? '' : $emit('update-hovered-family', null)"
        @click="$emit('update-detail-showing-family', family.uuid)"
      >
        <!-- Alias -->
        <b-td class="col-5 d-flex align-items-center ml-1 py-2">
          <!-- Drag Icon -->
          <div class="drag-btn pr-1 d-none d-md-block">
            <i
              v-if="hoveredFamily === family.uuid && walletFamily.length > 1"
              class="fas fa-grip-vertical cursor-move"
            />
          </div>
          <!-- Alias Content -->
          <i
            v-if="family.isDefault"
            v-b-tooltip.hover="$t('Payroll Accounts')"
            class="fa fa-cash-register"
          />
          <div>
            <span class="px-1">{{ family.description }}</span>
            <feather-icon
              v-if="detailsShowingFamily === family.uuid"
              size="20"
              icon="ChevronUpIcon"
            />
            <feather-icon
              v-else
              size="20"
              icon="ChevronDownIcon"
            />
            <!-- Shared Wallet Info -->
            <div
              v-if="family.shares.length && family.shares[0].owner && isWalletShared(family.shares[0], userType)"
              class="ml-1"
            >
              <feather-icon icon="LinkIcon" />
              <small class="small">
                {{ $t('Shared by') }}
                <i
                  class="ml-2"
                  :class="family.shares.length && family.shares[0].owner.__typename === 'User' ? 'fas fa-user' : 'fas fa-building'"
                />
                <span v-if="family.shares.length">
                  {{ family.shares[0].owner.name }}
                </span>
              </small>

              <b-badge
                variant="wz-teal"
                class="d-block"
              >
                <i
                  class="fa"
                  :class="getSharedDirection(family.shares[0].shareDirection, {icon: true})"
                />
                {{ family.shares[0].shareDirection ? getSharedDirection(family.shares[0].shareDirection) : '' }}
              </b-badge>
            </div>
          </div>
        </b-td>

        <!-- Wallet Type -->
        <b-td class="col-4 px-0">
          <span v-if="isWalletShared(family, userType, 'family')">
            {{ $t('Shared Account') }}
          </span>
          <span v-else>
            {{ family.familyType === 'OPENWALLET' ? $t('Open Account') : $t('Percent Based Account') }}
          </span>
        </b-td>

        <!-- Total Balance -->
        <b-td
          class="col-2 px-0"
          @click.stop="$emit('balance-toggle')"
        >
          <span v-if="!showBalance">
            XXX.XX
          </span>

          <span v-else>{{ formatAmount(family.balance ? family.balance : 0) }}</span>
          <feather-icon
            class="ml-1 cursor-pointer"
            :icon="showBalance ? 'EyeOffIcon' : 'EyeIcon'"
          />
        </b-td>

        <!-- Actions -->
        <b-td class="col-1 text-md-right px-0 pr-md-2">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none p-0"
            no-caret
            right
          >
            <!-- "More" Icon -->
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="24"
              />
            </template>
            <!-- Edit Wallet Alias -->
            <b-dropdown-item
              @click.stop="$emit('show-modal', family, 'edit-fam-details-modal')"
            >
              <i class="fa fa-edit mr-1" />
              {{ $t('Rename') }}
            </b-dropdown-item>

            <!-- View Family Statement -->
            <b-dropdown-item
              @click.stop="$router.push({ name: 'wallet-statement', params: { userType, walletFamily: family.uuid} })"
            >
              <i class="fa fa-eye mr-1" />
              {{ $t('View Statement') }}
            </b-dropdown-item>

            <!-- Add New Open Wallet -->
            <b-dropdown-item
              v-if="family.familyType === 'OPENWALLET'"
              @click.stop="$emit('show-modal', family, 'add-new-wallet-modal')"
            >
              <i class="fa fa-plus mr-1" />
              {{ $t('Add New Account') }}
            </b-dropdown-item>
          </b-dropdown>
        </b-td>
      </b-tr>

      <!-- Alt Accounts -->
      <b-collapse :visible="detailsShowingFamily === family.uuid">
        <alt-accounts
          :index="index"
          :user-type="userType"
          :wallet="family"
          :accounts="family.shares"
          :family-type="family.familyType"
          :used-banks="usedBanks"
          :wallet-family="allWalletFamily"
          :is-default="family.isDefault"
          :show-balance="showBalance"
          @fetchWallet="selectedWallet => $emit('fetch-wallet', selectedWallet)"
          @walletUpdated="(family, wallet, alias) => $emit('wallet-updated', family, wallet, alias)"
          @balance-toggle="$emit('balance-toggle')"
        />
      </b-collapse>
    </div>
  </div>
</template>

<script>
import {
  BTr, BTd, BDropdown, BDropdownItem, BCollapse, BBadge,
} from 'bootstrap-vue'
import AltAccounts from './AltAccounts.vue'

export default {
  components: {
    BTr,
    BTd,
    BDropdown,
    BDropdownItem,
    BCollapse,
    BBadge,
    AltAccounts,
  },
  props: {
    walletFamily: {
      type: Array,
      required: true,
    },
    userType: {
      type: String,
      required: true,
    },
    usedBanks: {
      type: Array,
      required: true,
    },
    allWalletFamily: {
      type: Array,
      required: true,
    },
    showBalance: {
      type: Boolean,
      default: false,
    },
    isWalletShared: {
      type: Function,
      required: true,
    },
    getSharedDirection: {
      type: Function,
      required: true,
    },
    detailsShowingFamily: {
      type: String,
      default: null,
    },
    hoveredFamily: {
      type: String,
      default: null,
    },
  },
}
</script>
