<template>
  <div>
    <div class="pl-0">
      <!-- Configure Account Alert -->
      <b-alert
        v-if="!wallet"
        show
        variant="warning"
        class="small text-warning cursor-pointer p-1"
      >
        <div
          class="d-flex align-items-center"
          @click="$router.push({ name: `wallet-accounts`, params: { userType, default: true } })"
        >
          <feather-icon
            icon="AlertTriangleIcon"
            class="mr-1"
          />
          <p>{{ $t('actions.configure-account') }}</p>
        </div>
      </b-alert>
    </div>

    <!-- Alt Accounts Table -->
    <b-overlay :show="isLoading">
      <b-card class="wallet-table mb-0">
        <!-- Table Header -->
        <div class="table-header d-flex align-items-center">
          <h6
            v-for="field in fields"
            :key="field.key"
            :class="field.classes"
          >
            {{ $t(field.label) }}
          </h6>
        </div>

        <!-- Table Contents -->

        <!-- Mobile View without Draggable -->
        <div v-if="altAccounts.length && IS_MOBILE()">
          <alt-account-table
            :hovered-account="hoveredAccount"
            :alt-accounts="altAccounts"
            :family-type="familyType"
            :user-type="userType"
            :show-balance="showBalance"
            :wallet-family="walletFamily"
            :accounts="accounts"
            :wallet="wallet"
            :selected-wallet="selectedWallet"
            :index="index"
            @show-modal="(account, id) => showModal(account, id)"
            @update-hovered-account="uuid => hoveredAccount = uuid"
            @balance-toggle="$emit('balance-toggle')"
            @show-banking-info="wallet => showBankingInfoModal(wallet)"
            @set-selected-wallet="wallet => selectedWallet = wallet"
            @set-wallet-action="action => walletAction = action"
          />
        </div>

        <!-- Draggable View with Draggable -->
        <draggable
          v-else-if="altAccounts.length"
          v-model="altAccounts"
          @end="orderAltAccounts"
        >
          <alt-account-table
            :hovered-account="hoveredAccount"
            :alt-accounts="altAccounts"
            :family-type="familyType"
            :user-type="userType"
            :show-balance="showBalance"
            :wallet-family="walletFamily"
            :accounts="accounts"
            :wallet="wallet"
            :selected-wallet="selectedWallet"
            :index="index"
            @show-modal="(account, id) => showModal(account, id)"
            @update-hovered-account="uuid => hoveredAccount = uuid"
            @balance-toggle="$emit('balance-toggle')"
            @show-banking-info="showBankingInfoModal"
            @set-selected-wallet="wallet => selectedWallet = wallet"
            @set-wallet-action="action => walletAction = action"
          />
        </draggable>
        <div v-else>
          <p class="mb-0 text-center">
            {{ $t('table.no-accounts') }}
          </p>
        </div>
      </b-card>
    </b-overlay>

    <!-- Modals -->
    <!-- Select Banking Info Modal -->
    <b-overlay :show="isLoading">
      <select-banking-info
        v-if="selectedWallet"
        :banking-info="availableBanks"
        :user-type="userType"
        :index="index"
        :selected-wallet="selectedWallet"
        :attach-wallet="true"
        :is-fetching="isFetchingBankingInfo"
        @refetch="getBankingInfo"
        @payment-select="infoId => { attachWallet(infoId) }"
      />
    </b-overlay>

    <!-- Edit Alias Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`edit-wallet-${index}-${userType}-modal`"
      :title="$t('Rename Account')"
      hide-footer
      no-close-on-backdrop
    >
      <edit-wallet-alias
        :no-title="true"
        :wallet="{walletUid: wallet.uuid, shareUid: selectedWallet.uuid, description: selectedWallet.description}"
        :user-type="userType"
        @wallet-updated="alias => onWalletUpdated(alias)"
        @cancel="() => { $bvModal.hide(`edit-wallet-${index}-${userType}-modal`) }"
      />
    </b-modal>

    <!-- Merge Wallets Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`merge-accounts-${index}-${userType}-modal`"
      :title="$t('wallet.merge-accounts-into', { wallet: selectedWallet.description })"
      size="xl"
      hide-footer
      no-close-on-backdrop
    >
      <merge-wallets
        :merge-into="selectedWallet"
        :parent-wallet="wallet.uuid"
        :user-type="userType"
        :wallets="accounts"
        :share-types="shareTypes"
        @back="() => { $bvModal.hide(`merge-accounts-${index}-${userType}-modal`) }"
        @wallet-merge-success="() => { $emit('fetchWallet', wallet); $bvModal.hide(`merge-accounts-${index}-${userType}-modal`) }"
      />
    </b-modal>

    <!-- Split Wallet Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`split-account-${index}-${userType}-modal`"
      :title="$t('wallet.split-account', { wallet: selectedWallet.description })"
      size="xl"
      hide-footer
      no-close-on-backdrop
    >
      <create-split-wallet
        :wallet-to-split="selectedWallet"
        :parent-wallet="wallet.uuid"
        :user-type="userType"
        :used-banks="usedBanks"
        :is-default-wallet="isDefault"
        @back="() => { $bvModal.hide(`split-account-${index}-${userType}-modal`) }"
        @wallet-config-success="$emit('fetchWallet', wallet); $bvModal.hide(`split-account-${index}-${userType}-modal`)"
      />
    </b-modal>

    <!-- Transfer Fund Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`transfer-fund-${index}-${userType}-modal`"
      :title="$t('wallet.transfer-fund-from', { wallet: selectedWallet.description })"
      hide-footer
      no-close-on-backdrop
    >
      <transfer-fund
        :wallet="selectedWallet"
        :wallet-uid="wallet.uuid"
        :available-family="availableFamily"
        :is-modal="true"
        :user-type="userType"
        :action="walletAction"
        @transfer-success="onActionSuccess(`transfer-fund-${index}-${userType}-modal`)"
        @back="() => { $bvModal.hide(`transfer-fund-${index}-${userType}-modal`) }"
      />
    </b-modal>

    <!-- Withdraw Fund Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`withdraw-fund-${index}-${userType}-modal`"
      :title="$t('wallet.withdraw-from', { wallet: selectedWallet.description })"
      hide-footer
      no-close-on-backdrop
    >
      <withdraw-fund
        :wallet="selectedWallet"
        :parent-wallet="wallet.uuid"
        :user-type="userType"
        :is-modal="true"
        :charges="charges"
        :action="walletAction"
        @back="() => { $bvModal.hide(`withdraw-fund-${index}-${userType}-modal`) }"
        @withdraw-success="onActionSuccess(`withdraw-fund-${index}-${userType}-modal`)"
      />
    </b-modal>

    <!-- Make Reconciliation Modal -->
    <make-edit-reconciliation
      v-if="selectedWallet"
      :index="index"
      :wallet="selectedWallet"
      :parent-wallet="wallet.uuid"
      :user-type="userType"
      @reconciliation-success="$emit('fetchWallet', wallet)"
    />

    <!-- Share Wallet Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`share-wallet-${index}-${userType}-modal`"
      :title="$t('wallet.share-wallet', { wallet: selectedWallet.description})"
      hide-footer
      no-close-on-backdrop
    >
      <share-wallet
        :wallet="selectedWallet"
        :parent-wallet="wallet.uuid"
        :user-type="userType"
        :is-modal="true"
        @back="$bvModal.hide(`share-wallet-${index}-${userType}-modal`)"
        @share-success="onActionSuccess(`share-wallet-${index}-${userType}-modal`)"
      />
    </b-modal>

    <!-- Share Revoke Confirm Modal -->
    <confirm-modal
      v-if="selectedWallet"
      :modal="walletAction === 'revoke-share' ? `revoke-sharing-${index}-${userType}-modal` : `revoke-wallet-${index}-${userType}-modal`"
      :title="walletAction === 'revoke-share' ? $t('Revoke Account Sharing') : $t('Revoke Share Invitation')"
      :alert="`${walletAction === 'revoke-share' ? $t('messages.revoke-account-sharing', { user: selectedWallet.description }) : $t('messages.revoke-share-invitation', { user: selectedWallet.description })}`"
      alert-variant="none"
      icon="AlertTriangleIcon"
      icon-size="40"
      icon-color="danger"
      ok-variant="danger"
      @confirm="walletAction === 'revoke-share' ? revokeWalletSharing() : revokeWalletShareInvite()"
    >
      <b-form-group
        v-if="walletAction === 'revoke-share'"
        :label="$t('Remarks')"
      >
        <b-form-input
          v-model="shareRevokeRemarks"
          type="text"
          :placeholder="$t('Remarks')"
        />
      </b-form-group>
    </confirm-modal>

    <!-- Deactivate Wallet Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`deactivate-wallet-${index}-${userType}-modal`"
      :title="$t('Deactivate Account')"
      no-close-on-backdrop
    >
      <deactivate-wallet :selected-wallet="selectedWallet" />
      <template #modal-footer>
        <b-overlay class="text-right">
          <b-button
            class="mr-1 mb-1"
            :disabled="isLoading"
            @click="() => { selectedWallet = null; walletAction = null; $bvModal.hide(`deactivate-wallet-${index}-${userType}-modal`) }"
          >
            {{ $t('Cancel') }}
          </b-button>
          <span v-if="selectedWallet.balance">
            <b-button
              variant="primary"
              :disabled="isLoading"
              class="mr-1 mb-1"
              @click="showModal(selectedWallet, `transfer-fund-${index}-${userType}-modal`)"
            >
              {{ $t('Transfer Fund') }}
            </b-button>
            <b-button
              v-if="selectedWallet.wallet.__typename === 'BankWallet'"
              variant="primary"
              :disabled="isLoading"
              class="mr-1 mb-1"
              @click="showModal(selectedWallet, `withdraw-fund-${index}-${userType}-modal`)"
            >
              {{ $t('Withdraw to Bank') }}
            </b-button>
          </span>
          <b-button
            v-else
            variant="danger"
            class="mb-1"
            :disabled="isLoading"
            @click="deactivateWallet"
          >
            {{ $t('Deactivate') }}
          </b-button>
        </b-overlay>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BOverlay, VBTooltip, BAlert, BButton, BModal, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import useWallet from '@/utils/wallet'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import SelectBankingInfo from './reusables/SelectBankingInfo.vue'
import MergeWallets from './WalletActions/MergeWallets.vue'
import CreateSplitWallet from './WalletActions/CreateSplitWallet.vue'
import EditWalletAlias from './WalletActions/EditWalletAlias.vue'
import TransferFund from './WalletActions/TransferFund.vue'
import WithdrawFund from './WalletActions/WithdrawFund.vue'
import ShareWallet from './WalletActions/ShareWallet.vue'
import MakeEditReconciliation from './WalletActions/MakeEditReconciliation.vue'
import DeactivateWallet from './WalletActions/DeactivateWallet.vue'
import ConfirmModal from './reusables/ConfirmModal.vue'
import AltAccountTable from './AltAccountTable.vue'

const { getWalletIcon, isWalletShared, GET_SHARED_DIRECTION } = useWallet()

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BOverlay,
    BAlert,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    draggable,
    SelectBankingInfo,
    MergeWallets,
    CreateSplitWallet,
    EditWalletAlias,
    TransferFund,
    WithdrawFund,
    ShareWallet,
    MakeEditReconciliation,
    DeactivateWallet,
    ConfirmModal,
    AltAccountTable,
  },
  props: {
    userType: {
      type: String,
      default: () => 'users',
    },
    wallet: {
      type: Object,
      default: () => {},
    },
    accounts: {
      type: Array,
      default: () => [],
    },
    usedBanks: {
      type: Array,
      default: () => [],
    },
    walletFamily: {
      type: Array,
      default: () => [],
    },
    familyType: {
      type: String,
      default: () => 'PERCENTBASED',
    },
    index: {
      type: Number,
      default: () => null,
    },
    isDefault: {
      type: Boolean,
      default: () => false,
    },
    showBalance: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      getWalletIcon,
      isWalletShared,
      GET_SHARED_DIRECTION,
      shareTypes: [
        { text: 'Alt Account', value: 'INTERNALWALLET' },
        { text: 'Bank Account', value: 'BANKWALLET' },
        { text: 'Payroll Account', value: 'PAYROLLWALLET' },
      ],
      altAccounts: [],
      isLoading: false,
      bankingInfo: [],
      selectedWallet: null,
      company: this.$store.state.project.selectedCompany,
      charges: {},
      walletAction: null,
      shareRevokeRemarks: '',
      hoveredAccount: null,
      isFetchingBankingInfo: false,
    }
  },
  computed: {
    ...mapGetters('permissions', ['canEditCompany']),
    fields() {
      return [
        { key: 'account', label: 'Account Name', classes: `${this.familyType === 'PERCENTBASED' ? 'col-5' : 'col-6'} py-1 pl-4` },
        ...(this.familyType === 'PERCENTBASED' ? [{ key: 'sharePercent', label: 'Share Percent', classes: 'px-0 px-md-2 col-2 col-md-3' }] : []),
        { key: 'totalBalance', label: 'Total Balance', classes: this.familyType === 'PERCENTBASED' ? 'px-0 px-md-2 col-2' : 'col-3 col-md-4' },
        { key: 'actions', label: 'Actions', classes: 'col-3 col-md-2 text-right' },
      ]
    },
    availableBanks() {
      if (this.usedBanks && this.bankingInfo.data) {
        const bankingInfo = this.bankingInfo.data.filter(info => this.usedBanks.findIndex(
          bank => bank.id === info.information.id,
        ) === -1)
        return bankingInfo
      }
      return this.bankingInfo.data
    },
    availableFamily() {
      let excludeOutbound = this.walletFamily
      if (this.userType === 'users') {
        excludeOutbound = this.walletFamily.filter(wallet => wallet.shares.length && (!this.isWalletShared(wallet.shares[0], this.userType) || (this.isWalletShared(wallet.shares[0], this.userType) && wallet.shares[0].shareDirection !== 'OUTBOUND')))
      }

      return excludeOutbound.map(({
        uuid, description, shares, familyType,
      }) => ({
        uuid,
        label: description,
        shares: shares.filter(share => share.uuid !== this.selectedWallet.uuid),
        familyType,
      }))
    },
  },
  watch: {
    accounts: {
      handler(val) {
        if (val) this.altAccounts = val
      },
      immediate: true,
    },
  },
  methods: {
    orderAltAccounts({ oldIndex, newIndex }) {
      if (oldIndex !== newIndex) {
        let api = 'updateUserWalletShareOrders'
        const params = {
          walletUid: this.wallet.uuid,
          orderedShareUids: this.altAccounts.map(account => account.uuid),
        }

        if (this.userType === 'company') {
          api = 'updateCompanyWalletShareOrders'
          params.companyUid = this.company
        }

        useApollo[this.userType][api](params).then(response => {
          this.showSuccessMessage({
            data: {
              message: response.data[api].message,
            },
          })
        }).catch(error => {
          this.showErrorMessage(error)
        })
      }
    },
    getCompanyCharges() {
      useApollo.company.getBillingCharges().then(response => {
        this.charges = response.data.workzone.extraInformations?.data[0]?.information
        if (!this.charges) this.charges = {}
      })
    },
    getWallet() {
      let api = 'getUserWallet'
      let params = {}
      let res = 'me'
      if (this.userType === 'company') {
        api = 'getCompanyWallet'
        params = { company: this.company }
        res = 'company'
      }

      this.isLoading = true
      useApollo[this.userType][api](params).then(response => {
        const data = response.data[res]?.wallet
        this.wallet = data
        this.accounts = data ? data.shares : []

        this.usedBanks = this.wallet?.shares.filter(share => share.wallet.__typename === 'BankWallet').map(bankWallet => ({
          id: bankWallet.wallet.information.id,
          alias: bankWallet.wallet.information.alias,
        }))
      }).finally(() => { this.isLoading = false })
    },
    showBankingInfoModal(wallet) {
      this.selectedWallet = wallet
      if (!this.bankingInfo.total) this.getBankingInfo()

      this.$nextTick(() => { this.$bvModal.show(`select-banking-info-${this.selectedWallet.uuid}-${this.index}-${this.userType}`) })
    },
    getBankingInfo() {
      let api = 'getMyBillingInfo'
      let res = 'me'
      let params = {}
      this.isFetchingBankingInfo = true

      if (this.userType === 'company') {
        api = 'getCompanyBillingInfo'
        res = 'company'
        params = {
          companyUid: this.company,
        }
      }

      useApollo[this.userType][api](params).then(response => {
        this.bankingInfo = response.data[res]?.extraInformations
      }).finally(() => {
        this.isFetchingBankingInfo = false
      })
    },
    attachWallet(infoId) {
      this.isLoading = true
      this.$bvModal.hide(`select-banking-info-${this.selectedWallet.uuid}-${this.index}-${this.userType}`)
      const info = this.bankingInfo.data.find(bank => bank.information.id === infoId)
      const infoType = info.information.__typename
      this.selectedInfoName = info.information.alias
      const form = {
        wallet: {
          walletUid: this.wallet.uuid,
          shareUid: this.selectedWallet.uuid,
        },
        input: {
          informationId: infoId,
          informationType: infoType.substr(0, infoType.length - 11).toUpperCase(),
        },
      }

      let res = 'attachBankingInformationToInternalWallet'

      if (this.userType === 'company') {
        form.companyUid = this.company
        res = 'attachBankingInformationToCompanyInternalWallet'
      }

      useApollo[this.userType].attachBankToWallet(form).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[res].message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('fetchWallet', this.wallet)
        this.isLoading = false
      })
    },
    revokeWalletShareInvite() {
      this.isLoading = true
      const params = {
        shareUid: this.selectedWallet.uuid,
        checksum: this.selectedWallet.invites.data[0].checksum,
      }
      let api = 'revokeWalletShareInvite'

      if (this.userType === 'company') {
        params.companyUid = this.company
        api = 'revokeCompanyWalletShareInvite'
      }

      useApollo[this.userType][api](params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[api].message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('fetchWallet', this.wallet)
        this.isLoading = false
      })
    },
    revokeWalletSharing() {
      this.isLoading = true
      const params = {
        wallet: {
          walletUid: this.selectedWallet.family.uuid,
          shareUid: this.selectedWallet.uuid,
        },
        remarks: this.shareRevokeRemarks,
      }
      let api = 'revokeWalletShare'

      if (this.userType === 'company') {
        params.companyUid = this.company
        api = 'revokeCompanyWalletShare'
      }

      useApollo[this.userType][api](params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[api].message,
          },
        })
      }).catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.$emit('fetchWallet', this.wallet)
          this.isLoading = false
        })
    },
    showModal(wallet, modal, action = null) {
      if (modal === `withdraw-fund-${this.index}-${this.userType}-modal` && !this.charges.length) this.getCompanyCharges()
      if (action === 'deactivate') this.walletAction = action
      this.selectedWallet = wallet

      this.$nextTick(() => { this.$bvModal.show(modal) })
    },
    onActionSuccess(modal) {
      this.$bvModal.hide(modal)
      this.$emit('fetchWallet', this.wallet)
      if (this.walletAction === 'deactivate') {
        this.isLoading = true
        setTimeout(() => {
          this.selectedWallet = this.accounts.find(account => account.uuid === this.selectedWallet.uuid)
          this.isLoading = false
        }, 1000)
      } else this.selectedWallet = null
    },
    onWalletUpdated(alias) {
      this.$emit('walletUpdated', this.wallet.uuid, this.selectedWallet.uuid, alias)
      this.$bvModal.hide(`edit-wallet-${this.index}-${this.userType}-modal`)
    },
    deactivateWallet() {
      this.isLoading = true
      const params = {
        wallet: {
          walletUid: this.wallet.uuid,
          shareUid: this.selectedWallet.uuid,
        },
      }
      let api = 'deactivateWalletShare'

      if (this.userType === 'company') {
        api = 'deactivateCompanyWalletShare'
        params.companyUid = this.company
      }
      useApollo[this.userType][api](params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[api].message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('fetchWallet', this.wallet)
        this.selectedWallet = null
        this.$bvModal.hide(`deactivate-wallet-${this.index}-${this.userType}-modal`)
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/wallet-page.scss";
</style>
