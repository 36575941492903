<template>
  <div>
    <div v-if="selectedWallet.balance">
      <h4 class="my-2">
        {{ $t('Available Balance') }}: {{ formatAmount(selectedWallet.balance) }}
      </h4>
      <b-alert
        show
        class="d-flex align-items-center p-1"
      >
        <feather-icon
          icon="InfoIcon"
          size="34"
        />
        <p class="ml-1">
          The funds in this account should be transferred
          <span v-if="selectedWallet.wallet.__typename === 'BankWallet'">or withdrawn</span>
          before deactivating.
        </p>
      </b-alert>
    </div>
    <div
      v-else
      class="p-1"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          icon="AlertTriangleIcon"
          size="28"
          color="#ea5455"
        />
        <p class="ml-1 mb-0">
          {{ $t('messages.deactivate-account-message', { wallet: selectedWallet.description }) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
  props: {
    selectedWallet: {
      type: Object,
      required: true,
    },
  },
}
</script>
