<template>
  <div>
    <!-- Edit wallet -->
    <b-card
      v-if="editing"
      class="wallet-card"
    >
      <edit-wallet-alias
        :wallet="{walletUid: parentWallet, shareUid: share.uuid, description: share.description}"
        :user-type="userType"
        @cancel="() => { editing = false }"
        @wallet-updated="alias => { editing = false; $emit('update', alias) }"
      />
    </b-card>

    <!-- Wallet Card -->
    <b-card
      v-else
      class="wallet-card"
    >
      <div class="d-flex pt-1">
        <b-avatar
          :size="68"
          class="mr-2"
        >
          <i
            :class="getWalletIcon(share.wallet.__typename)"
            class="fa-32"
          />
        </b-avatar>

        <div class="w-100">
          <div class="d-flex justify-content-between align-items-baseline">
            <h4>{{ share.description }}</h4>

            <!-- More Actions -->
            <b-dropdown
              v-if="!noButtons && !isWalletShared(share, userType)"
              size="lg"
              variant="link"
              toggle-class="text-decoration-none p-0"
              no-caret
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="20"
                />
              </template>
              <!-- Edit Alias -->
              <b-dropdown-item @click="() => { editing = true }">
                <feather-icon
                  icon="Edit2Icon"
                />
                {{ $t('Rename') }}
              </b-dropdown-item>
              <!-- Attach Internal Wallet to Bank -->
              <b-dropdown-item
                v-if="share.wallet.__typename === 'InternalWallet'"
                @click="showBankingInfoModal"
              >
                <feather-icon icon="LinkIcon" />
                {{ $t('Link with Bank A/c') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <h6 class="text-secondary">
            {{ $t('Balance') }}: {{ formatAmount(share.balance ? share.balance : 0) }}
          </h6>
          <p v-if="isWalletShared(share, userType)">
            {{ $t('Shared by') }}: {{ share.owner.name }}
          </p>
          <p
            v-else
            class="text-secondary"
          >
            {{ $t('Share Percent') }}: {{ share.sharePercent }}%
          </p>
        </div>
      </div>

      <!-- Card Footer -->
      <div
        class="d-flex align-items-center card-footer pb-0 pl-1"
        :class="noButtons ? 'justify-content-end' : 'justify-content-between'"
        @mouseleave="() => { showButtons = false }"
      >
        <!-- Buttons -->
        <div
          v-if="!noButtons"
          class="d-flex"
        >
          <!-- For Shared Wallets -->
          <div v-if="isWalletShared(share, userType)">
            <div
              v-if="share.shareDirection !== 'INBOUND'"
              v-b-tooltip.hover
              :title="$t('Transfer Fund')"
            >
              <b-button
                class="edit-btn mr-1"
                :variant="share.balance < 1 || (walletFamilyCount < 2 && totalWallets < 2) ? 'secondary' : 'success'"
                :disabled="share.balance < 1 || (walletFamilyCount < 2 && totalWallets < 2)"
                @click="() => { $emit('action', 'transfering', share, parentWallet) }"
              >
                <i class="fa fa-random" />
              </b-button>
            </div>

          </div>
          <!-- For Regular Wallets -->
          <div v-else>
            <!-- Show Buttons Arrow -->
            <b-button
              v-if="!showButtons && (!isWalletShared(share, userType) || share.shareDirection !== 'INBOUND')"
              v-b-tooltip.hover="$t('Show Actions')"
              variant="primary"
              class="edit-btn"
              @click="() => { showButtons = true }"
            >
              <i class="fa fa-angle-right" />
            </b-button>
            <div
              v-if="showButtons"
              class="d-flex"
            >
              <!-- Split Button -->
              <div
                v-if="!isWalletShared(share, userType)"
                v-b-tooltip.hover
                :title="$t('Split Account')"
              >
                <b-button
                  :variant="share.sharePercent < 2 ? 'secondary' : 'primary'"
                  class="edit-btn mr-1"
                  :disabled="share.sharePercent < 2"
                  @click="() => { $emit('action', 'splitting', share, parentWallet) }"
                >
                  <i class="fa fa-share-alt" />
                </b-button>
              </div>

              <!-- Merge Button -->
              <div
                v-if="!isWalletShared(share, userType)"
                v-b-tooltip.hover
                :title="$t('Merge Other Accounts')"
              >
                <b-button
                  :disabled="!showMergeWalletOption"
                  :variant="showMergeWalletOption ? 'primary' : 'secondary'"
                  class="edit-btn mr-1"
                  @click="() => { $emit('action', 'merging', share, parentWallet) }"
                >
                  <i class="rotate-180 fa fa-project-diagram" />
                </b-button>
              </div>

              <!-- Share Wallet Button -->
              <div
                v-if="!isWalletShared(share, userType) && share.wallet.__typename === 'InternalWallet' && !share.invites.total"
                v-b-tooltip.hover
                :title="share.sharedTo ? $t('Already Shared') : $t('Share Account')"
              >
                <b-button
                  :variant="share.sharedTo ? 'secondary' : 'primary'"
                  class="edit-btn mr-1"
                  :disabled="share.sharedTo ? true : false"
                  @click="() => { $emit('action', 'sharing', share, parentWallet) }"
                >
                  <i class="fa fa-share" />
                </b-button>
              </div>

              <!-- Share Invitation Revoke Button -->
              <div
                v-else-if="share.invites.total"
                v-b-tooltip.hover="$t('Revoke Share Invitation')"
                @click="showRevokeConfirm(share)"
              >
                <b-button
                  variant="danger"
                  class="edit-btn mr-1"
                >
                  <i class="fa fa-unlink" />
                </b-button>
              </div>

              <!-- Transfer Fund Button -->
              <div
                v-b-tooltip.hover
                :title="share.balance < 1 ? $t('Insufficient Fund') : $t('Transfer Fund')"
              >
                <b-button
                  class="edit-btn mr-1"
                  :variant="share.balance < 1 || (walletFamilyCount < 2 && totalWallets < 2) ? 'secondary' : 'success'"
                  :disabled="share.balance < 1 || (walletFamilyCount < 2 && totalWallets < 2)"
                  @click="() => { $emit('action', 'transfering', share, parentWallet) }"
                >
                  <i class="fa fa-random" />
                </b-button>
              </div>

              <!-- Withdraw Fund Button -->
              <div
                v-b-tooltip.hover
                :title="share.balance < 1 ? $t('Insufficient Fund') : $t('Withdraw Fund')"
              >
                <b-button
                  v-if="share.wallet.__typename === 'BankWallet'"
                  :variant="share.balance < 1 ? 'secondary' : 'success'"
                  class="edit-btn mr-1"
                  :disabled="share.balance < 1"
                  @click="() => { $emit('action', 'withdrawing', share, parentWallet) }"
                >
                  <i class="fa fa-circle-dollar-to-slot" />
                </b-button>
              </div>

              <!-- Transfer Scheduler Button -->
              <div
                v-b-tooltip.hover="$t('Schedule Fund Transfer')"
                @click="() => { $emit('action', 'scheduling', share, parentWallet) }"
              >
                <b-button
                  variant="success"
                  class="edit-btn mr-1"
                >
                  <i class="fa fa-calendar-check" />
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer Details -->
        <div
          v-if="!showButtons"
          class="text-right"
        >
          <div v-if="share.wallet.__typename === 'InternalWallet'">
            {{ $t('Alt Account') }}
          </div>
          <div v-else-if="share.wallet.__typename === 'PayrollWallet'">
            {{ $t('Payroll Account') }}
          </div>
          <div
            v-else
            class="text-right"
          >
            <p class="mb-0">
              {{ share.wallet.information.bankName }} ({{ getBillingType(share.wallet.information.__typename) }})
            </p>
            <p class="mb-0">
              {{ share.wallet.information.bankAccount }}
            </p>
          </div>
          <small
            v-if="share.sharedTo && (share.owner && !isWalletShared(share, userType))"
            id="shared-info"
          >
            <feather-icon icon="LinkIcon" />
            {{ $t('Shared with') }} {{ share.sharedTo.name }}
          </small>
          <small
            v-else-if="share.invites.total"
            v-b-tooltip.hover.bottom="`Shared with ${share.invites.data[0].sharedTo.name} - Pending`"
          >
            <i class="fa fa-stopwatch" />
            {{ $t('Share Invitation Pending') }}
          </small>
          <b-badge
            v-if="(share.owner && isWalletShared(share, userType)) || (share.sharedTo && (share.owner && !isWalletShared(share, userType)))"
            variant="wz-teal"
            class="d-block"
          >
            <i
              class="fa"
              :class="GET_SHARED_DIRECTION(share.shareDirection, {icon: true})"
            />
            {{ share.shareDirection ? GET_SHARED_DIRECTION(share.shareDirection) : '' }}
          </b-badge>
        </div>
      </div>
    </b-card>

    <!-- Select Banking Info To Attach Modal -->
    <select-banking-info
      v-if="bankingInfo"
      :user-type="userType"
      :banking-info="availableBanks"
      :index="index"
      :selected-wallet="share"
      :attach-wallet="true"
      @refetch="getBankingInfo"
      @payment-select="infoId => { attachWallet(infoId) }"
    />

    <!-- Confirm Modal -->
    <confirm-modal
      :modal="currentAction.modal"
      :title="currentAction.title"
      :description="currentAction.description"
      :ok-variant="currentAction.okVariant"
      @confirm="revoking ? revokePendingShareInvite() : attachWallet()"
    />
  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, VBTooltip, BDropdownItem, BDropdown, BBadge,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import useWallet from '@/utils/wallet'
import EditWalletAlias from '../WalletActions/EditWalletAlias.vue'
import SelectBankingInfo from './SelectBankingInfo.vue'
import ConfirmModal from './ConfirmModal.vue'

const { getWalletIcon, isWalletShared, GET_SHARED_DIRECTION } = useWallet()

export default {
  components: {
    BCard,
    BButton,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BBadge,
    ConfirmModal,
    EditWalletAlias,
    SelectBankingInfo,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    share: {
      type: Object,
      default: () => {},
    },
    shareTypes: {
      type: Array,
      default: () => [],
    },
    noButtons: {
      type: Boolean,
      default: () => false,
    },
    totalWallets: {
      type: Number,
      default: () => 0,
    },
    userType: {
      type: String,
      default: () => 'users',
    },
    usedBanks: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: () => null,
    },
    parentWallet: {
      type: String,
      default: () => '',
    },
    walletFamilyCount: {
      type: Number,
      default: () => 0,
    },
    isDefault: {
      type: Boolean,
      deafult: () => false,
    },
  },
  data() {
    return {
      getWalletIcon,
      isWalletShared,
      GET_SHARED_DIRECTION,
      editing: false,
      attaching: false,
      revoking: false,
      bankingInfo: [],
      selectedInfoName: '',
      showButtons: false,
      company: this.$store.state.project.selectedCompany,
      selectedShare: null,
    }
  },
  computed: {
    availableBanks() {
      if (this.usedBanks) {
        const bankingInfo = this.bankingInfo.filter(info => this.usedBanks.findIndex(
          bank => bank.id === info.information.id,
        ) === -1)
        return bankingInfo
      }
      return this.bankingInfo
    },
    showMergeWalletOption() {
      if (this.userType === 'users' && this.totalWallets > 1) return true
      if (this.share.wallet.__typename === 'PayrollWallet' && this.totalWallets > 1) return true
      if (this.userType === 'company') {
        if (this.isDefault && this.totalWallets > 2) return true
        if (!this.isDefault && this.totalWallets > 1) return true
      }
      return false
    },
    currentAction() {
      if (this.revoking) {
        return {
          modal: `revoke-share-${this.index}`,
          title: `Revoke Pending Share of ${this.share.description}`,
          description: `Are you sure you want to revoke the pending share invitation of ${this.share.description}?`,
          okVariant: 'danger',
        }
      }
      return {
        modal: `attach-bank-confirm-modal-${this.index}`,
        title: `Link with ${this.share.description}`,
        description: `Are you sure you want to link ${this.share.description} with ${this.selectedInfoName}?`,
        okVariant: 'primary',
      }
    },
  },
  methods: {
    getshareType(type) {
      return this.shareTypes.find(wallet => wallet.value === type).text
    },
    showBankingInfoModal() {
      if (!this.bankingInfo.length) this.getBankingInfo()
      this.$nextTick(() => { this.$bvModal.show(`select-banking-info-${this.share.uuid}-${this.index}-${this.userType}`) })
    },
    getBankingInfo() {
      this.isLoading = true
      let api = 'getMyBillingInfo'
      let params = {}
      let res = 'me'

      if (this.userType === 'company') {
        api = 'getCompanyBillingInfo'
        res = 'company'
        params = {
          companyUid: this.company,
        }
      }

      useApollo[this.userType][api](params).then(response => {
        this.bankingInfo = response.data[res]?.extraInformations.data
      }).finally(() => { this.isLoading = false })
    },
    attachWallet(infoId) {
      this.isLoading = true

      const info = this.bankingInfo.find(bank => bank.information.id === infoId)
      const infoType = info.information.__typename
      this.selectedInfoName = info.information.alias
      const form = {
        wallet: {
          walletUid: this.parentWallet,
          shareUid: this.share.uuid,
        },
        input: {
          informationId: infoId,
          informationType: infoType.substr(0, infoType.length - 11).toUpperCase(),
        },
      }

      let res = 'attachBankingInformationToInternalWallet'
      if (this.userType === 'company') {
        form.companyUid = this.company
        res = 'attachBankingInformationToCompanyInternalWallet'
      }

      useApollo[this.userType].attachBankToWallet(form).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[res].message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('refetch')
        this.isLoading = false
      })
    },
    showRevokeConfirm(share) {
      this.revoking = true
      this.selectedShare = share
      this.$nextTick(() => this.$bvModal.show(`revoke-share-${this.index}`))
    },
    revokePendingShareInvite() {
      this.isLoading = true
      const params = {
        shareUid: this.selectedShare.uuid,
        checksum: this.selectedShare.invites.data[0].checksum,
      }
      let api = 'revokeWalletShareInvite'

      if (this.userType === 'company') {
        params.companyUid = this.company
        api = 'revokeCompanyWalletShareInvite'
      }

      useApollo[this.userType][api](params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[api].message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('refetch')
        this.isLoading = false
      })
    },
    getBillingType(type) {
      switch (type) {
        case 'AchBillingInformation':
          return 'ACH'
        case 'FxBillingInformation':
          return 'FX'
        default:
          return 'SWIFT'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/wallet-page.scss";
</style>
