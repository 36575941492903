<template>
  <b-table-simple>
    <div
      v-for="account, accIndex in altAccounts"
      :key="account.uuid"
      class="cursor-move"
      @mouseenter="IS_MOBILE() ? '' : $emit('update-hovered-account', account.uuid)"
      @mouseleave="IS_MOBILE() ? '' : $emit('update-hovered-account', null)"
    >
      <b-tr
        class="d-flex align-items-center table-row cursor-pointer"
        :class="{striped: accIndex%2}"
      >
        <!-- Account Name -->
        <b-td
          class="d-flex align-items-center ml-0 ml-md-1 pl-0 pl-md-1 py-1"
          :class="familyType === 'PERCENTBASED' ? 'col-5' : 'col-6'"
        >
          <!-- Drag Icon -->
          <div class="drag-btn">
            <i
              v-if="hoveredAccount === account.uuid && altAccounts.length > 1"
              class="fas fa-grip-vertical cursor-move pr-1"
            />
          </div>
          <!-- Name Content -->
          <b-avatar
            size="55"
            class="mr-2"
          >
            <i
              class="f-25"
              :class="getWalletIcon(account.wallet.__typename)"
            />
          </b-avatar>
          <div>
            <h6 class="mb-0 mt-0-5">
              {{ account.description }}
            </h6>

            <!-- Bank Wallet -->
            <div v-if="account.wallet.__typename === 'BankWallet'">
              <p class="small mb-0">
                {{ account.wallet.information.bankName }}
              </p>
              <p class="small mb-0">
                {{ account.wallet.information.bankAccount }}
              </p>

              <!-- Edit Bank Details -->
              <b-link
                v-if="hasHigherPermission"
                class="small d-flex align-items-center"
                :class="{ 'no-opacity': !IS_MOBILE() && hoveredAccount !== account.uuid }"
                :to="{ name: userType === 'users' ? 'user-general-settings' : 'companySetting', hash: '#banking', params: {id: account.wallet.information.id, fromPage: 'accounts'} }"
              >
                <feather-icon icon="EditIcon" />
                <span>{{ $t('Edit Bank Details') }}</span>
              </b-link>
            </div>

            <!-- Other Wallet -->
            <p
              v-else
              class="small mb-0"
            >
              {{ getWalletType(account.wallet.__typename) }}
            </p>

            <!-- Shared Info -->
            <small
              v-if="account.sharedTo && (account.owner && !isWalletShared(account, userType))"
              id="shared-info"
            >
              <feather-icon icon="LinkIcon" />
              {{ $t('Shared with') }}

              <br class="d-md-none">
              <span v-if="account.sharedTo">
                <i
                  class="ml-1 ml-md-2"
                  :class="account.sharedTo.__typename === 'User' ? 'fas fa-user' : 'fas fa-building'"
                />
                {{ account.sharedTo.name }}
              </span>
              <b-badge
                class="d-block mt-0-5 py-0-5"
                variant="wz-teal"
              >
                <i
                  class="fa"
                  :class="GET_SHARED_DIRECTION(account.shareDirection, {icon: true})"
                />
                {{ GET_SHARED_DIRECTION(account.shareDirection) }}
              </b-badge>
            </small>
            <small
              v-else-if="account.invites.total"
              v-b-tooltip.hover.bottom="`Shared with ${account.invites.data[0].sharedTo.name} - Pending`"
            >
              <i class="fa fa-stopwatch" />
              {{ $t('Share Invitation Pending') }}
            </small>
          </div>
        </b-td>

        <!-- Share Percent -->
        <b-td
          v-if="familyType === 'PERCENTBASED'"
          class="col-2 col-md-3"
        >
          <span v-if="isWalletShared(account, userType)">-</span>
          <span
            v-else
            class="pl-2"
          >{{ account.sharePercent }}%</span>
        </b-td>

        <!-- Total Balance -->
        <b-td
          class="px-0 px-md-1"
          :class="familyType === 'PERCENTBASED' ? 'col-2' : 'col-3 col-md-4'"
          @click.stop="() => $emit('balance-toggle')"
        >
          <span v-if="!showBalance">
            XXX.XX
          </span>

          <span v-else>{{ formatAmount(account.balance ? account.balance : 0) }}</span>
          <feather-icon
            class="ml-1 cursor-pointer"
            :icon="showBalance ? 'EyeOffIcon' : 'EyeIcon'"
          />
        </b-td>

        <!-- Actions -->
        <b-td
          id="tour-account-actions"
          class="col-3 col-md-2 pr-1 pr-md-2"
        >
          <b-row class="justify-content-end align-items-center">
            <b-col
              cols="10"
              class="px-0"
            >
              <!-- Buttons -->
              <div class="d-flex justify-content-end w-100">
                <!-- View Statement -->
                <b-button
                  v-b-tooltip.hover="IS_MOBILE() ? '' : $t('View Statement')"
                  variant="primary"
                  class="edit-btn mr-1"
                  @click.stop="$router.push({ name: 'wallet-statement', params: { account: account.uuid, userType, walletFamily: wallet.uuid} })"
                >
                  <i class="fa fa-eye" />
                </b-button>

                <!-- Transfer Fund -->
                <span
                  v-if="(account.owner && !isWalletShared(account, userType)) || (!isWalletShared(account, userType) || account.shareDirection !== 'INBOUND')"
                  v-b-tooltip.hover
                  :title="IS_MOBILE() ? '' : account.balance < 1 ? $t('Insufficient Fund') : $t('Transfer Fund')"
                >
                  <b-button
                    class="edit-btn mr-1"
                    :variant="account.balance < 1 || (walletFamily.length < 2 && accounts.length < 2) ? 'secondary' : 'success'"
                    :disabled="account.balance < 1 || (walletFamily.length < 2 && accounts.length < 2)"
                    @click.stop="$emit('show-modal', account, `transfer-fund-${index}-${userType}-modal`)"
                  >
                    <i class="fa fa-random" />
                  </b-button>
                </span>

                <!-- Withdraw Fund -->
                <span
                  v-b-tooltip.hover
                  :title="IS_MOBILE() ? '' : account.balance < 1 ? $t('Insufficient Fund') : $t('Withdraw Fund')"
                >
                  <b-button
                    v-if="account.wallet.__typename === 'BankWallet'"
                    class="edit-btn"
                    :variant="account.balance < 1 ? 'secondary' : 'success'"
                    :disabled="account.balance < 1"
                    @click.stop="$emit('show-modal', account, `withdraw-fund-${index}-${userType}-modal`)"
                  >
                    <i class="fa fa-circle-dollar-to-slot" />
                  </b-button>
                </span>
              </div>
            </b-col>

            <b-col
              cols="2"
              class="px-0"
            >
              <b-dropdown
                v-if="account.owner && !isWalletShared(account, userType)"
                size="lg"
                variant="link"
                toggle-class="text-decoration-none p-0"
                no-caret
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="24"
                  />
                </template>
                <!-- Edit Wallet Alias -->
                <b-dropdown-item
                  @click.stop="$emit('show-modal', account, `edit-wallet-${index}-${userType}-modal`)"
                >
                  <i class="fa fa-edit mr-1" />
                  {{ $t('Rename') }}
                </b-dropdown-item>

                <!-- Attach Internal Wallet to Bank -->
                <b-dropdown-item
                  v-if="account.wallet.__typename === 'InternalWallet' && hasHigherPermission"
                  @click.stop="$emit('show-banking-info', account)"
                >
                  <i class="fa fa-link mr-1" />
                  {{ $t('Link with Bank A/c') }}
                </b-dropdown-item>

                <!-- Split Wallet -->
                <b-dropdown-item
                  v-if="familyType === 'PERCENTBASED'"
                  @click.stop="$emit('show-modal', account, `split-account-${index}-${userType}-modal`)"
                >
                  <i class="fa fa-share-alt mr-1" />
                  {{ $t('Split Account') }}
                </b-dropdown-item>

                <!-- Merge Wallet -->
                <b-dropdown-item
                  v-if="familyType === 'PERCENTBASED' && showMergeWalletOption(account)"
                  @click.stop="$emit('show-modal', account, `merge-accounts-${index}-${userType}-modal`)"
                >
                  <i class="rotate-180 fa fa-project-diagram mr-1" />
                  {{ $t('Merge Other Accounts') }}
                </b-dropdown-item>

                <!-- Make Reconciliation -->
                <b-dropdown-item
                  v-if="userType === 'company'"
                  @click.stop="$emit('show-modal', account, `reconcile-transaction-${index}-${userType}-modal`)"
                >
                  <i class="fa fa-receipt mr-1" />
                  {{ $t('Add Manual Transaction') }}
                </b-dropdown-item>

                <!-- Share / Revoke Share Wallet -->
                <b-dropdown-item
                  v-if="hasHigherPermission && account.wallet.__typename === 'InternalWallet' && !account.invites.total"
                  :variant="account.sharedTo ? 'danger' : ''"
                  @click.stop="() => { $emit('set-wallet-action', account.sharedTo ? 'revoke-share' : null); $emit('show-modal', account, account.sharedTo ? `revoke-sharing-${index}-${userType}-modal` : `share-wallet-${index}-${userType}-modal`) }"
                >
                  <i
                    class="fa mr-1"
                    :class="account.sharedTo ? 'fa-unlink' : 'fa-share'"
                  />
                  {{ account.sharedTo ? $t('Revoke Sharing') : $t('Share Account') }}
                </b-dropdown-item>

                <!-- Deactivate Wallet -->
                <b-dropdown-item
                  v-if="familyType === 'OPENWALLET'"
                  variant="danger"
                  @click.stop="$emit('show-modal', account, `deactivate-wallet-${index}-${userType}-modal`)"
                >
                  <i class="fa fa-exclamation-triangle mr-1" />
                  {{ $t('Deactivate Account') }}
                </b-dropdown-item>

                <!-- Revoke Share Invitation -->
                <b-dropdown-item
                  v-if="hasHigherPermission && account.invites.total"
                  variant="danger"
                  @click.stop="() => { $emit('set-wallet-action', 'revoke-invitation'); $emit('show-modal', account, `revoke-wallet-${index}-${userType}-modal`) }"
                >
                  <i class="fa fa-unlink mr-1" />
                  {{ $t('Revoke Share Invitation') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-td>
      </b-tr>
    </div>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BTd, BTr, BDropdown, BDropdownItem, BButton, BAvatar, BBadge, BLink, BRow, BCol,
} from 'bootstrap-vue'
import useWallet from '@/utils/wallet'
import useApollo from '@/plugins/graphql/useApollo'

const { getWalletIcon, isWalletShared, GET_SHARED_DIRECTION } = useWallet()

export default {
  components: {
    BRow,
    BCol,
    BTableSimple,
    BTd,
    BTr,
    BDropdown,
    BDropdownItem,
    BButton,
    BAvatar,
    BBadge,
    BLink,
  },
  props: {
    hoveredAccount: {
      type: String,
      default: null,
    },
    altAccounts: {
      type: Array,
      default: () => [],
    },
    familyType: {
      type: String,
      default: 'PERCENTBASED',
    },
    userType: {
      type: String,
      default: 'users',
    },
    showBalance: {
      type: Boolean,
      default: false,
    },
    walletFamily: {
      type: Array,
      default: () => [],
    },
    accounts: {
      type: Array,
      default: () => [],
    },
    wallet: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: () => null,
    },
    selectedWallet: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      getWalletIcon,
      isWalletShared,
      GET_SHARED_DIRECTION,
      walletAction: null,
      charges: {},
    }
  },
  methods: {
    hasHigherPermission() {
      if (this.userType === 'users' || this.canEditCompany) return true

      return false
    },
    getWalletType(type) {
      if (type === 'InternalWallet') return 'Alt Account'
      if (type === 'PayrollWallet') return 'Payroll Account'
      return 'Bank Account'
    },
    showMergeWalletOption(wallet) {
      if (this.userType === 'users' && this.accounts.length > 1) return true
      if (wallet.wallet.__typename === 'PayrollWallet' && this.accounts.length > 1) return true
      if (this.userType === 'company') {
        if (this.isDefault && this.accounts.length > 2) return true
        if (!this.isDefault && this.accounts.length > 1) return true
      }
      return false
    },
    getCompanyCharges() {
      useApollo.company.getBillingCharges().then(response => {
        this.charges = response.data.workzone.extraInformations?.data[0]?.information
        if (!this.charges) this.charges = {}
      })
    },
  },
}
</script>
